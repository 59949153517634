import { Options, Vue } from "vue-class-component";
import { IEmergencyContact } from "@/types/EmergencyContact";
import WorkForceService from "@/shared/application/work-force-service-proxy";
import Breadcrumb from "@/components/Breadcrumb.vue";
import { IGender } from "@/types/Employee";
import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";
import ConfirmationModal from "@/components/modals/ConfirmationModal.vue";
import DropdownComponent from "@/components/DropdownComponent.vue";
import { notify } from "@/services/helpers";

const workForceService = new WorkForceService();

@Options({
  name: "EditEmergencyContact",
  components: {
    Breadcrumb,
    Form,
    Field,
    ErrorMessage,
    ConfirmationModal,
    Dropdown: DropdownComponent,
  },
  data: function () {
    return {
      loading: false,
      message: "",
      schema: yup.object().shape({
        firstName: yup
          .string()
          .required("Please provide the first name.")
          .max(75, "Must not exceed 75 characters in length."),
        lastName: yup
          .string()
          .required("Please provide the last name.")
          .max(75, "Must not exceed 75 characters in length."),
        gender: yup.string().required("Please select a gender."),
        relationship: yup
          .string()
          .required("Please provide the relationship.")
          .max(20, "Must not exceed 20 characters in length."),
        workPhone: yup
          .string()
          .required("Please provide the work phone number.")
          .min(11, "Must be at least 11 characters in length.")
          .max(15, "Must not exceed 15 characters in length."),
        mobilePhone: yup
          .string()
          .required("Please provide the mobile phone number.")
          .min(11, "Must be at least 11 characters in length.")
          .max(15, "Must not exceed 15 characters in length."),
        homePhone: yup
          .string()
          .required("Please provide the home phone number.")
          .min(11, "Must be at least 11 characters in length.")
          .max(15, "Must not exceed 15 characters in length."),
        emailAddress: yup
          .string()
          .email("Must be a valid email.")
          .required("Please provide the email address."),
      }),
    };
  },
  methods: {
    saveEmergencyContact(emergencyContact: IEmergencyContact) {
      workForceService
        .postOrPut<void>(
          `/emergencycontact/${this.emergencyContact.id}`,
          emergencyContact as IEmergencyContact,
          emergencyContact.id.toString()
        )
        .then((response) => {
          if (!response.isError) {
            this.goToRoute("/admin/emergencycontact");
          } else {
            return;
          }
        });
    },
  },
})
export default class EditEmergencyContact extends Vue {
  protected workForceService: WorkForceService = new WorkForceService();
  emergencyContact: IEmergencyContact = {
    firstName: "",
    lastName: "",
    id: 0,
    emailAddress: "",
    employeeId: 0,
    gender: "",
    mobilePhone: "",
    homePhone: "",
    relationship: "",
    workPhone: "",
  };
  loading = false;
  crumbs: any = ["Dashboard", "Emergency Contact", "Edit Emergency Contact"];
  gender: IGender[] = [
    { id: 0, type: "Male" },
    { id: 1, type: "Female" },
    { id: 2, type: "Prefer not to say" },
  ];
  gFields: any = { text: "type", value: "id" };

  async goToRoute(route: string) {
    await this.$router.push(route);
  }

  message = "";
  async save() {
    this.loading = true;
    this.message = "";
    this.emergencyContact.gender = this.emergencyContact.gender.toString();
    this.workForceService
      .postOrPut<void>(
        `/emergencycontact/${this.emergencyContact.id}`,
        this.emergencyContact as IEmergencyContact,
        this.emergencyContact.id.toString()
      )
      .then((response: any) => {
        this.loading = false;
        if (!response.isError) {
          notify("Contact updated successfully.", "Success", "success");
          this.goToRoute("/admin/emergencycontact");
        } else {
          notify(response.errors, "Error", "danger");
        }
      });
  }

  async created() {
    this.initialize();
  }

  genderId = -1;
  async initialize() {
    const emergencyContactId = this.$route.params.emergencyContactId;
    this.workForceService
      .get<IEmergencyContact>(`/emergencycontact/${emergencyContactId}`, false)
      .then((response: any) => {
        this.emergencyContact = response.content;
        this.genderId = parseInt(this.emergencyContact.gender);
      });
  }

  ggChange(args: any) {
    this.emergencyContact.gender = args.value.toString();
  }
}
